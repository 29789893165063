import { Button, CloseButton, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

const BottomFix = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [visible, setVisible] = useState(true);

  const onClick = () => {
    setVisible(false)
  }
  useEffect(() => {
    onClick()
  },[])

  return (
    <div
      onClose={onClose}
      className={'fixed bottom-0 w-full shadow py-2 bg-opacity-20 bg-primaryHover ' + (!visible && 'hidden')}
    >
      <div className="flex container justify-between items-center">
        <div className="text-xs flex-1">
          Sitemizi kullanmaya devam ederek sizlere daha iyi bir hizmet
          sunabilmek adına var olan çerezleri kullanmamıza izin vermiş
          oluyorsunuz.
          <br /> Detaylı bilgi almak için Çerez Politikasını ve Veri
          Politikasını inceleyebilirsiniz.
        </div>
        <div>
          <Button fontSize=".75rem" onClick={() => setVisible(false)}>
            Kapat
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BottomFix;
