import HTMLReactParser from 'html-react-parser';
import React from 'react';
import { Link } from 'react-router-dom';

const NewsContent = ({ title, body, imageUrl, createdDate, id }) => {
  return (
    <div
      className="px-4 py-2 border border-gray-200 rounded-sm max-h-[400px] max-w-300 overflow-hidden bg-slate-100"
      key={id}
    >
      <div className='bg-gray-100 w-full h-[150px] flex items-center justify-center pb-2 '>
      <img
        src={imageUrl}
        alt={title}
        className="max-w-[260px] pt-2 mx-auto max-h-[150px]"
      />
      </div>
      <h1 className="font-light text-xs text-gray-400">
        {new Date(createdDate).toLocaleString('tr-TR')}
      </h1>
      <Link to={`/medya/${id}`}>
        <h1 className="font-medium text-md">{title}</h1>
      </Link>

      <h5 className="font-medium text-xs mt-2 text-gray-600 max-h-[65px] text-ellipsis overflow-hidden">{HTMLReactParser(body)}</h5>
      <Link to={`/medya/${id}`}>
        <h5 className="font-medium text-[12px] mt-4 underline text-gray-400 hover:text-primary transition-all capitalize">
          Detayları İncele
        </h5>
      </Link>
    </div>
  );
};

export default NewsContent;
