import React from 'react';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaLinkedin} from 'react-icons/fa';
import {FaTwitter} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const SocialButtons = () => {
    
  return (
    <div className="flex ">
         
      <a href="https://www.facebook.com/profile.php?id=100095478190349" target='_blank' className='p-1 m-0.5 text-gray-100 hover:text-gray-700 bg-primary rounded-lg transition-all'>
        <FaFacebookSquare />
      </a>
      <a href='https://www.linkedin.com/company/movelland/' target='_blank' className='p-1 m-0.5 text-gray-100 hover:text-gray-700 bg-primary rounded-lg transition-all'>
        <FaLinkedin />
      </a>
      <a href='https://twitter.com/movelland' target='_blank' className='p-1 m-0.5 text-gray-100 hover:text-gray-700 bg-primary rounded-lg transition-all'>
        <FaTwitter />
      </a>
      <Link to="#" className='p-1 m-0.5 text-gray-100 hover:text-gray-700 bg-primary rounded-lg transition-all'>
        <FaInstagram />
      </Link> 
    </div>
  );
};

export default SocialButtons;
