import React, { useRef, useState } from 'react';
import BundledEditor from './BundleEditor';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';

const EditNews = ({ isOpen, onClose, id, oldBody, oldTitle, createdDate,imageUrl, oldLink,oldTarget,oldMobileImage,oldButtonText,oldRowNumber,oldStatus,oldMobileLink }) => {
  const [title, setTitle] = useState(oldTitle);
  const [image, setImage] = useState();
  const [link, setLink] = useState(oldLink);
  const [target, setTarget] = useState(oldTarget);
  const [mobileImage, setMobileImage] = useState();
  const [buttonText, setButtonText] = useState(oldButtonText);
  const [rowNumber, setRowNumber] = useState(oldRowNumber);
  const [status, setStatus] = useState(oldStatus);
  const [mobileLink, setMobileLink] = useState(oldMobileLink);
  const [description, setDescription] = useState(oldBody);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);


  console.log('id', id);

  //Editor
  const editorRef = useRef(null);
  const onChange = (content) => {
    setDescription(content);
  };

  const toast = useToast();
  const token = JSON.parse(localStorage.getItem('user'));
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('link', link);
      formData.append('target', target);
      formData.append('image', image);
      formData.append('mobileImage', mobileImage);
      formData.append('buttonText', buttonText);
      formData.append('rowNumber', rowNumber);
      formData.append('status', status);
      formData.append('mobileLink', mobileLink);
      formData.append('createdDate', createdDate);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Site: 'DamaskoHolding',
        },
      };

      const response = await axios.put(
        'https://api.damaskoholding.com/api/Slide/Update',
        formData,
        config
      );
      if (response.data) {
        setSubmitted(true);
        toast({
          title: 'Slayt Değiştirildi !',
          description: 'Güncel haberi görüntülemek için sayfayı yenileyin...',
          status: 'success',
          variant: 'solid',
          duration: 9000,
          position: 'top-right',
          isClosable: true,
        });
        await onClose();
      }
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        variant: 'solid',
        duration: 9000,
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      blockScrollOnMount={false}
    >
      <ModalOverlay
        bg="blackAlpha.50"
        backdropFilter="blur(5px) hue-rotate(90deg)"
      />
      <ModalContent padding={5} bgColor="gray.200">
        <ModalHeader p={0}>Slayt Ekle</ModalHeader>
        <ModalCloseButton />

        <section className="mt-3 text-sm">
          <form onSubmit={onSubmit} className="flex flex-col text-left">
            <label htmlFor="Title" className="mb-2">
              Slayt Başlığı
            </label>
            <input
              className="text-gray-900 border border-gray-400 px-4 py-2 rounded-md"
              type="text"
              name="Title"
              id="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label htmlFor="Body" className="my-2">
              Slayt İçeriği
            </label>

            {/* Bundle EdITOR */}
            <BundledEditor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={description}
              onEditorChange={onChange}
              value={description}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist',
                  'anchor',
                  'autolink',
                  'help',
                  'image',
                  'link',
                  'lists',
                  'searchreplace',
                  'table',
                  'wordcount',
                ],

                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />

            <label htmlFor="Link" className="mb-2">
              Link
            </label>
            <input
              className="text-gray-900 border border-gray-400 px-4 py-2 rounded-md"
              type="text"
              name="Link"
              id="Link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />

            <label htmlFor="Target" className="mb-2">
              Target
            </label>
            <input
              className="text-gray-900 border border-gray-400 px-4 py-2 rounded-md"
              type="text"
              name="Target"
              id="Target"
              value={target}
              onChange={(e) => setTarget(e.target.value)}
            />

            <label htmlFor="ButtonText" className="mb-2">
              Button Text
            </label>
            <input
              className="text-gray-900 border border-gray-400 px-4 py-2 rounded-md"
              type="text"
              name="ButtonText"
              id="ButtonText"
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
            />

            <label htmlFor="RowNumber" className="mb-2">
              Row Number
            </label>
            <input
              className="text-gray-900 border border-gray-400 px-4 py-2 rounded-md"
              type="text"
              name="RowNumber"
              id="RowNumber"
              value={rowNumber}
              onChange={(e) => setRowNumber(e.target.value)}
            />

            <label htmlFor="Status" className="mb-2">
              Status (1, 2 veya boş)
            </label>
            <input
              className="text-gray-900 border border-gray-400 px-4 py-2 rounded-md"
              type="text"
              name="Status"
              id="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            />

            <label htmlFor="MobileLink" className="mb-2">
              Mobile Link
            </label>
            <input
              className="text-gray-900 border border-gray-400 px-4 py-2 rounded-md"
              type="text"
              name="MobileLink"
              id="MobileLink"
              value={mobileLink}
              onChange={(e) => setMobileLink(e.target.value)}
            />

            <label htmlFor="file" className="mt-3">
              Slayt Görseli Ekle
              <Alert status="warning">
                <AlertIcon />
                <div className="flex flex-col">
                  <AlertTitle>Dikkat!</AlertTitle>
                  <AlertDescription>
                    Yüklenilen resmin boyutu 1Mb'tan fazla
                    olmamalıdır.JPG,PNG,JPEG ve SVG olarak yükleme
                    yapabilirsiniz...
                  </AlertDescription>
                </div>
              </Alert>
            </label>
            <input
              className="text-gray-300 my-5 rounded-md"
              id="file"
              type="file"
              onChange={(e) => {
                console.log(String(e.target.files[0]));
                setImage(e.target.files[0])
            }}
            />

            <label htmlFor="file" className="mt-3">
              Mobile Slayt Görseli Ekle
              <Alert status="warning">
                <AlertIcon />
                <div className="flex flex-col">
                  <AlertTitle>Dikkat!</AlertTitle>
                  <AlertDescription>
                    Yüklenilen resmin boyutu 1Mb'tan fazla
                    olmamalıdır.JPG,PNG,JPEG ve SVG olarak yükleme
                    yapabilirsiniz...
                  </AlertDescription>
                </div>
              </Alert>
            </label>
            <input
              className="text-gray-300 my-5 rounded-md"
              id="file"
              type="file"
              onChange={(e) => setMobileImage(e.target.files[0])}
            />

            <Button
              className="bg-slate-400 hover:bg-slate-300 py-4 inline-block outline-none"
              type="submit"
              disabled={submitted}
            >
              {loading ? (
                <Spinner size="sm" />
              ) : (
                <p>{!submitted ? 'Slaytı Düzenle' : 'Slayt Düzenlendi'}</p>
              )}
            </Button>
          </form>
        </section>
      </ModalContent>
    </Modal>
  );
};

export default EditNews;
