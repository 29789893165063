import React, { useState } from 'react';
import PhotoAlbum from "react-photo-album";
import 'yet-another-react-lightbox/styles.css';
import Lightbox from 'yet-another-react-lightbox';


import iso1 from '../../../assets/ISOBelge/iso2.jpg';
import iso2 from '../../../assets/ISOBelge/iso2.jpg';
import iso3 from '../../../assets/ISOBelge/iso3.jpg';
import iso4 from '../../../assets/ISOBelge/iso4.jpg';
import iso5 from '../../../assets/ISOBelge/iso5.jpg';
import iso6 from '../../../assets/ISOBelge/iso6.jpg';
import iso7 from '../../../assets/ISOBelge/iso7.jpg';

const images = [
  {
    src: iso1,
    original: iso1,
    width: 768,
    height: 1024,
    caption: 'ISO Belge',
  },
  {
    src: iso2,
    original: iso2,
    width: 768,
    height: 1024,
    caption: 'ISO Belge',
  },

  {
    src: iso3,
    original: iso3,
    width: 768,
    height: 1024,
    caption: 'ISO Belge',
  },
  {
    src: iso4,
    original: iso4,
    width: 768,
    height: 1024,
    caption: 'ISO Belge',
  },
  {
    src: iso5,
    original: iso5,
    width: 768,
    height: 1024,
    caption: 'ISO Belge',
  },
  {
    src: iso6,
    original: iso6,
    width: 768,
    height: 1024,
    caption: 'ISO Belge',
  },

  {
    src: iso7,
    original: iso7,
    width: 768,
    height: 1024,
    caption: 'ISO Belge',
  },
];

const slides = images.map(({ original, width, height }) => ({
  src: original,
  width,
  height,
}));

const KalitePolitika = () => {
  const [index, setIndex] = useState(-1);
  const handleClick = (index) => setIndex(index);

  return (
    <div className="">
      <p className="main-title pb-4">ISO Kalite Belgelerimiz</p>
      <PhotoAlbum
        layout="rows"
        photos={images}
        targetRowHeight={400}
        onClick={({ index }) => setIndex(index)}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </div>
  );
};

export default KalitePolitika;
