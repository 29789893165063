import React from 'react';
import Slider from './Slider';
import AboutUs from './AboutUs';
import Home from '../../../pages/Home';
import Sectors from './Sectors';
import Numbers from './Numbers';
import Bulten from './Bulten';
import OurStory from './OurStory';
import TabNews from './TabNews';

const index = () => {
  return (
    <Home>
      <Slider />
      <AboutUs />
      <Numbers />
      <Sectors />
      <OurStory />
      <TabNews />
      
      <Bulten />
      
    </Home>
  );
};

export default index;
