import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Home from "./Home";
import BreadcrumbSec from "../components/Complex Components/BreadcrumbSec";
import Vizyonumuz from "../components/Complex Components/Kurumsal/Vizyonumuz";
import Misyonumuz from "../components/Complex Components/Kurumsal/Misyonumuz";
import Hikayemiz from "../components/Complex Components/Kurumsal/Hikayemiz";
import { SiBlockchaindotcom } from "react-icons/si";
import ISGPolitika from "../components/Complex Components/Kurumsal/ISGPolitika";
import KalitePolitika from "../components/Complex Components/Kurumsal/KalitePolitika";


export default function Kurumsal() {
  const tabItems = [
    {
      id: 1,
      name: "Hikayemiz",
    },
    {
      id: 2,
      name: "Vizyonumuz",
    },
    {
      id: 3,
      name: "Misyonumuz",
    },
    {
      id: 4,
      name: "İş Sağlığı ve Güvenliği Politikamız",
    },
    {
      id: 5,
      name: "Kalite Politikamız",
    },
  ];
  return (
    <div>
      <Home>
        <div>
          <BreadcrumbSec Current="Kurumsal" />
          <div className="container py-4 text-left">
            <Tabs
              orientation="vertical"
              size="lg"
              colorScheme="gray"
              variant="unstyled"
              className="flex flex-col lg:flex-row"
            >
              <div>
                <TabList paddingRight={5} paddingY="2rem">
                  {tabItems.map((tabItem) => (
                    <Tab
                      margin={0}
                      paddingY=".8rem"
                      key={tabItem.id}
                      display="flex"
                      justifyContent="start"
                      w={{ base: "100%", lg: "18rem" }}
                      textAlign="left"
                      borderBottom="2px"
                      borderBottomColor="gray.200"
                      fontSize="sm"
                      _selected={{
                        textAlign: "left",
                        color: "#1E293B",
                        fontWeight: "semibold",
                        borderBottom: "2px",
                        borderBottomColor: "gray.300",
                      }}
                    >
                      <div className="flex items-center mx-auto lg:m-0 lg:w-full">
                        <SiBlockchaindotcom className="mr-3 hidden lg:block" />
                        {tabItem.name}
                      </div>
                    </Tab>
                  ))}
                </TabList>
              </div>
              <div>
                <TabPanels>
                  <TabPanel>
                    <Hikayemiz />
                  </TabPanel>
                  <TabPanel>
                    <Vizyonumuz />
                  </TabPanel>
                  <TabPanel>
                    <Misyonumuz />
                  </TabPanel>
                  <TabPanel>
                    <ISGPolitika />
                  </TabPanel>
                  <TabPanel>
                    <KalitePolitika />
                  </TabPanel>
                </TabPanels>
              </div>
            </Tabs>
          </div>
        </div>
      </Home>
    </div>
  );
}
