import React from 'react';
import Home from './Home';
import BreadcrumbSec from '../components/Complex Components/BreadcrumbSec';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { SiBlockchaindotcom } from 'react-icons/si';
import Faaliyet from '../components/Complex Components/Sektorler/Faaliyet';
import Firmalar from '../components/Complex Components/Sektorler/Firmalar';

export default function Sektorler() {
  const sectors = [
    {
      id: 1,
      name: 'Faaliyet Alanlarımız',
    },
    {
      id: 2,
      name: 'Firmalarımız',
    },
  ];

  return (
    <div>
      <Home>
        <BreadcrumbSec Current="Faaliyet Alanları" />
        <div className="container py-8">
          <Tabs
            orientation="vertical"
            size="lg"
            colorScheme="gray"
            variant="unstyled"
          >
            <div className='flex flex-col lg:flex-row'>
            <div>
              <TabList paddingRight={5} paddingY="2rem">
                {sectors.map((sector) => {
                  return (
                    <Tab
                      margin={0}
                      paddingY=".8rem"
                      key={sector.id}
                      display="flex"
                      justifyContent="start"
                      w={{ base: "100%", lg: "18rem" }}
                      textAlign="left"
                      borderBottom="2px"
                      borderBottomColor="gray.200"
                      fontSize="sm"
                      _selected={{
                        textAlign: 'left',
                        color: '#1E293B',
                        fontWeight: 'semibold',
                        borderBottom: '2px',
                        borderBottomColor: 'gray.300',
                      }}
                    >
                      <div className="flex items-center mx-auto lg:m-0 lg:w-full">
                        <SiBlockchaindotcom className="mr-3 hidden lg:block" />
                        {sector.name}
                      </div>
                    </Tab>
                  );
                })}
              </TabList>
            </div>
            <div>
              <TabPanels>
                <TabPanel>
                  <Faaliyet />
                </TabPanel>
                <TabPanel>
                  <Firmalar />
                </TabPanel>
              </TabPanels>
            </div>
            </div>
          </Tabs>
        </div>
      </Home>
    </div>
  );
}
