import axios from 'axios';

const API_URL = 'process.env.API_URL';

// Create new blog
const createBlog = async (blogData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'multipart/form-data',
    },
  };

  const response = await axios.post(
    'https://api.damaskoholding.com/api/Blog/Add',
    blogData,
    config
  );
    
  return response.data;
};

// Get user blogs
const getBlogs = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    'https://api.damaskoholding.com/api/Blog/GetList',
    config
  );

  return response.data;
};

// Update user blog
const updateBlog = async (newData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    'https://api.damaskoholding.com/api/Blog/Update',
    newData,
    config
  );

  return response.data;
};

// Delete user blog
const deleteBlog = async (IdAndImage, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    'https://api.damaskoholding.com/api/Blog/Delete',
    IdAndImage,
    config
  );

  return response.data;
};

const deleteSlide = async (IdAndImage, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    'https://api.damaskoholding.com/api/Slide/Delete',
    IdAndImage,
    config
  );

  return response.data;
};

const blogService = {
  createBlog,
  getBlogs,
  updateBlog,
  deleteBlog,
  deleteSlide
};

export default blogService;
