import React from 'react'

const SiteHaritasi = () => {
  return (
    <div className='container mt-16 font-bold'>
        <h1 className='text-center'>Damasko Holding Web Sitesi</h1>
        <ul className='mt-10'>
            <li>1. Anasayfa(Kurumsal, Faaliyet Alanları, Medya, Kariyer, İletişim)</li>
            <li className='mt-2'>2. Kurumsal (<a className='text-blue-500' href='https://damaskoholding.com/kurumsal' target='_blanck'>https://damaskoholding.com/kurumsal</a>)</li>
            <li className='ml-5'>a) Hikayemiz</li>
            <li className='ml-5'>b) 1910'den Bugüne</li>
            <li className='ml-5'>c) Tekstil Faaliyetleri</li>
            <li className='mt-2'>3. Faaliyet Alanları (<a className='text-blue-500' href='https://damaskoholding.com/sektorler' target='_blanck'>https://damaskoholding.com/sektorler</a>)</li>
            <li className='ml-5'>a) Tekstil Faaliyetleri</li>
            <li className='ml-5'>b) İnşaat Faaliyetleri</li>
            <li className='ml-5'>c) Yazılım Faaliyetleri</li>
            <li className='mt-2'>4. Medya (<a className='text-blue-500' href='https://damaskoholding.com/medya' target='_blanck'>https://damaskoholding.com/medya</a>)</li>
            <li className='ml-5'>a) Haberler</li>
            <li className='mt-2'>5. Kariyer (<a className='text-blue-500' href='https://damaskoholding.com/kariyer' target='_blanck'>https://damaskoholding.com/kariyer</a>)</li>
            <li className='ml-5'>a) Yetenek ve Kariyer Yönetimi</li>
            <li className='mt-2'>6. İletişim (<a className='text-blue-500' href='https://damaskoholding.com/iletisim' target='_blanck'>https://damaskoholding.com/iletisim</a>)</li>
            <li className='ml-5'>a) Adresimiz</li>
            <li className='ml-5'>b) Telefon Numaramız</li>
            <li className='ml-5'>c) E-posta Adresimiz</li>

        </ul>
    </div>
  )
}

export default SiteHaritasi