import React, { useState } from 'react';
import axios from 'axios';

const Bulten = () => {
  const url = 'https://api.damaskoholding.com/api/Bulten/Add';
  const [email, setEmail] = useState("");
  const [register, setRegister] = useState(false);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
     const res = await axios.post(url, { email });
     if (res.status === 200) {
      setRegister(true);
     }
    } catch (error) {
      console.log(error.message);
    }
    finally {
      setEmail('');
    }
  };

  return (
    <div className="w-full bg-primary ">
      <div className="container flex flex-col basis-1/5 xl:basis-2/5 lg:flex-row justify-between section-top-padding px-12 lg:px-0 py-8">
      <div className='text-gray-100 text-lg font-semibold text-center lg:text-left pb-4 lg:pb-0 xl:mr-64'>
        Hakkımızda en güncel haberleri takip etmek isterseniz bültenimize abone
        olun...
      </div>

      <form onSubmit={handleSubmit} className="basis-4/5 xl:basis-2/5 flex justify-center lg:justify-end">
        <label
          htmlFor="email"
          className="mb-2 text-sm font-medium text-gray-900 sr-only"
        >
          Email
        </label>
        <div className="relative w-full">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-gray-500 focus:border-gray-500 "
            placeholder="E-posta adresinizi girin"
            required
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-primary hover:bg-primaryHover focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2 "
          >
            {register ? "Abone Olundu" : "Abone Ol"}
          </button>
        </div>
      </form>
      </div>
    </div>
  );
};

export default Bulten;
