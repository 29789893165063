import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import 'swiper/css/effect-coverflow';

const CaroulselGaleri = ({ slides }) => {
  return (
    <div className="max-w-[992px] bg-primary p-5 rounded-sm">
      <Swiper
        centeredSlides={true}
        breakpoints={{
          992: {
            slidesPerView: 3,
            coverflowEffect: {
              rotate: 50,
              stretch: 0,
              depth: 50,
              modifier: 1,
              slideShadows: true,
            },
          },
        }}
        effect={'coverflow'}
        grabCursor={true}
        slidesPerView={1}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 50,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        navigation={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        loop={true}
        className="mySwiper "
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            <img
              src={slide.img}
              alt="slides"
              className="w-[448px] mx-auto h-auto"
              loading="lazy"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CaroulselGaleri;
