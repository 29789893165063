import React, { useEffect, useState } from 'react';
import NewsContent from './NewsContent';
import axios from 'axios';
import { SkeletonText } from '@chakra-ui/react';

const Haberler = () => {
  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.damaskoholding.com/api/Blog/GetList`
      );
      const { data } = response.data;
      setNews(data);
    } catch (error) {
      console.log(error.response);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <h2 className="font-bold mb-5 text-xl">Haberler</h2>
      <div className="grid grid-cols-2 lg:grid-cols-3  gap-4">
        {loading ? (
          <div>
            <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' />
          </div>
        ) : (
          news.map((item) => {
            return <NewsContent key={item.id} {...item} />;
          })
        )}
      </div>
    </div>
  );
};

export default Haberler;
