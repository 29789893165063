import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Spinner, useDisclosure, useToast } from '@chakra-ui/react';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import axios from 'axios';
import { deleteBlog, deleteSlide } from '../../../features/blogs/blogSlice';
import EditSlide from './EditSlide';

const DashboardSlides = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [blogs, setBlogs] = useState('');
  const [edit, setEdit] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);

  const { user } = useSelector((state) => state.auth);

  // const { isOpen, onOpen, onClose } = useDisclosure();
  const modalEditSlide = useDisclosure()

  const toast = useToast();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://api.damaskoholding.com/api/Slide/GetList`
      );
      const { data } = response.data;
      setBlogs(data);
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user) {
      navigate('/admin');
    }
    fetchData();
  }, []);

  const handleBlogEdit = (id) => {
    setSelectedBlogId(id);
    modalEditSlide.onOpen()

  };

  return (
    <>
      <section className="w-full grid grid-cols-3 gap-5 ">
        {isLoading ? (
          <Spinner />
        ) : blogs.length > 0 ? (
          blogs.map((blog) => (
            <div
              key={blog.id}
              className=" mb-2 p-5 border border-white rounded-md"
            >
              <div className="flex items-center justify-between">
                <div className="text-sm text-gray-400">
                  <div className="py-1 ">Eklenme Tarihi:</div>{' '}
                  {new Date(blog.createdDate).toLocaleString('tr-TR')}
                </div>
                <div className="flex flex-row-reverse">
                  <div
                    onClick={() =>
                      dispatch(
                        deleteSlide({ id: blog.id, image: blog.image })
                      )
                        .then(modalEditSlide.onClose())
                        .then(
                          toast({
                            title: 'Slayt Silindi!',
                            description: 'Sayfayi yenileyiniz',
                            status: 'success',
                            duration: 9000,
                            position: 'top-right',
                            isClosable: true,
                          })
                        )
                    }
                    className="p-2 rounded-full bg-red-600 cursor-pointer hover:bg-red-400 ml-2"
                  >
                    <AiFillDelete />
                  </div>

                  <div
                    className="p-2 rounded-full bg-gray-400 cursor-pointer hover:bg-gray-300"
                    onClick={() => handleBlogEdit(blog.id)}
                  >
                    <AiFillEdit />
                  </div>
                  {selectedBlogId === blog.id && (
                    <EditSlide
                      id={blog.id}
                      imageUrl={blog.image}
                      oldTitle={blog.title}
                      oldBody={blog.description}
                      createdDate={blog.createdDate}
                      onClose={modalEditSlide.onClose}
                      isOpen={modalEditSlide.isOpen}
                      oldLink={blog.link}
                      oldTarget={blog.target}
                      oldMobileImage={blog.mobileImage}
                      oldButtonText={blog.buttonText}
                      oldRowNumber={blog.rowNumber}
                      oldStatus={blog.status}
                      oldMobileLink={blog.mobileLink}
                    />
                  )}
                </div>
              </div>
              <div className="max-h-[320px] overflow-hidden">
                <img
                  src={blog.image}
                  alt=""
                  className="w-[270px] my-2 max-h-[150px]"
                />
                <div className="font-semibold uppercase">{blog.title}</div>
                <div className="text-sm">{parse(blog.description)}</div>
              </div>
            </div>
          ))
        ) : (
          'Slayt Yok!'
        )}
      </section>

    </>
  );
};

export default DashboardSlides;
