import React from 'react';
import Navbar from './Navbar';
import Logo from './Logo';
import Hamburger from './Hamburger';
import SocialButtons from '../../Basic Components/SocialButtons';
import { BsFillTelephoneFill } from 'react-icons/bs';
export default function index() {
  return (
    <div className="w-full ">
      <div className="container mx-auto flex justify-between items-center lg:items-start">
        <a
          href="tel:+902242158508"
          className="font-medium text-primary py-2 hidden lg:block"
        >
          <div className="flex items-center">
            <BsFillTelephoneFill />
            <span className="ml-2 text-sm">0(224)215 85 08</span>
          </div>
        </a>
        <Logo />
        <Hamburger />
        <div className="hidden lg:inline-block py-2">
          <SocialButtons />
        </div>
      </div>
      <div className=''>
      <div className="w-full flex justify-center bg-slate-200">
        <Navbar />
      </div>
      </div>
    </div>
  );
}
