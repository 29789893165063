import Home from './Home';
import BreadcrumbSec from '../components/Complex Components/BreadcrumbSec';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import Haberler from '../components/Complex Components/Medya/Haberler';
import Videolar from '../components/Complex Components/Medya/Videolar';
import BasindaBiz from '../components/Complex Components/Medya/BasindaBiz';
import GaleriContent from '../components/Complex Components/Medya/GaleriContent';

const Galeri = () => {
  const captions = [
    { id: 1, name: 'Haberler' },
    { id: 2, name: 'Galeri' },
    { id: 3, name: 'Videolar' },
    { id: 4, name: 'Basında Biz' },
  ];

  return (
    <Home>
      <BreadcrumbSec Current="Galeri" />
      <div className="container py-4 text-left">
        <Tabs
          orientation="vertical"
          size="lg"
          colorScheme="gray"
          variant="unstyled"
          isLazy
          className="flex flex-col lg:flex-row"
        >
          <TabList paddingRight={5} paddingY="2rem">
            {captions.map((caption) => (
              <Tab
                margin={0}
                paddingY=".8rem"
                key={caption.id}
                display="flex"
                justifyContent="start"
                width={{ base: '100%', lg: '18rem' }}
                textAlign="left"
                borderBottom="2px"
                borderBottomColor="gray.200"
                fontSize="sm"
                _selected={{
                  textAlign: 'left',
                  color: '#1E293B',
                  fontWeight: 'semibold',
                  borderBottom: '2px',
                  borderBottomColor: 'gray.300',
                }}
              >
                <div className="flex items-center mx-auto lg:m-0 lg:w-full">
                  {caption.name}
                </div>
              </Tab>
            ))}
          </TabList>

          <div className="lg:basis-3/4">
            <TabPanels>
              <TabPanel>
                <Haberler />
              </TabPanel>
              <TabPanel>
                <GaleriContent />
              </TabPanel>
              <TabPanel>
                <Videolar />
              </TabPanel>
              <TabPanel>
                <BasindaBiz />
              </TabPanel>
            </TabPanels>
          </div>
        </Tabs>
      </div>
    </Home>
  );
};

export default Galeri;
