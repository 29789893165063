import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate } from 'react-router-dom';
import { login, reset } from '../features/auth/authSlice';
import logo from '../assets/logo-damasko-1.png';
import { Spinner, useToast } from '@chakra-ui/react';

const LoginandSignUp = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user,isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth);
  
    useEffect(() => {
    if (user) {
      navigate('/dashboard');
      toast({
        title: 'Giriş Başarılı!',
        position: 'top-right',
        status: 'success',
        isClosable: true,
        duration: 9000,
      });
    }
    if (isError) {
      return toast({
        title: 'Kullanıcı adı ya da Parola Hatalı!!',
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }
    
  }, [isError,user]);


  //ONCHANGE
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  //SUBMIT
  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(login(formData));
  };

  if (isLoading) {
    return (
      <div className="bg-gray-900 flex h-screen w-full justify-center items-center text-white">
        <Spinner size="xl" thickness=".5rem" />
      </div>
    );
  }

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="/" className="flex items-center mb-6  dark:text-white">
          <img className="w-24" src={logo} alt="logo" />
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Admin Giriş
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={onSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  E-posta adresiniz
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-stone-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="eposta@eposta.com"
                  onChange={onChange}
                />
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Şifre
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={onChange}
                />
              </div>

              <button
                type="submit"
                className="w-full text-white bg-stone-400 hover:bg-stone-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
              >
                Giriş Yap
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginandSignUp;
