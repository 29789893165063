import img1 from '../../../assets/gallery/5.jpeg';
import img2 from '../../../assets/gallery/6.jpeg';
import img3 from '../../../assets/gallery/7.jpeg';
import img4 from '../../../assets/gallery/8.jpeg';

import img5 from '../../../assets/gallery/1.jpg';
import img6 from '../../../assets/gallery/2.jpg';
import img7 from '../../../assets/gallery/3.jpg';
import img8 from '../../../assets/gallery/4.jpg';

//Fuar Gorselleri


export const fuarImg = [
  {
    id: 1,
    img: img1,
    title: 'Yarını Bugünden İnşaa Ediyoruz',
    desc: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor, eligendi explicabo. Quibusdam temporibus est voluptas!',
    buttonText: 'Göz At',
  },
  {
    id: 2,
    img: img2,
    title: 'Temiz Bir Gelecek İçin Çalışıyoruz',
    desc: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor, eligendi explicabo. Quibusdam temporibus est voluptas!',
    buttonText: 'Incele',
  },
  {
    id: 3,
    img: img3,
    title: 'Temiz Bir Gelecek İçin Çalışıyoruz',
    desc: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor, eligendi explicabo. Quibusdam temporibus est voluptas!',
    buttonText: 'Incele',
  },
  {
    id: 4,
    img: img4,
    title: 'Temiz Bir Gelecek İçin Çalışıyoruz',
    desc: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor, eligendi explicabo. Quibusdam temporibus est voluptas!',
    buttonText: 'Incele',
  },
];

//DamaskoTekstil Gorselleri

export const tekstilImg = [
    {
      id: 1,
      img: img5,
      title: 'Yarını Bugünden İnşaa Ediyoruz',
      desc: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor, eligendi explicabo. Quibusdam temporibus est voluptas!',
      buttonText: 'Göz At',
    },
    {
      id: 2,
      img: img6,
      title: 'Temiz Bir Gelecek İçin Çalışıyoruz',
      desc: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor, eligendi explicabo. Quibusdam temporibus est voluptas!',
      buttonText: 'Incele',
    },
    {
      id: 3,
      img: img7,
      title: 'Temiz Bir Gelecek İçin Çalışıyoruz',
      desc: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor, eligendi explicabo. Quibusdam temporibus est voluptas!',
      buttonText: 'Incele',
    },
    {
      id: 4,
      img: img8,
      title: 'Temiz Bir Gelecek İçin Çalışıyoruz',
      desc: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolor, eligendi explicabo. Quibusdam temporibus est voluptas!',
      buttonText: 'Incele',
    },
  ];