import './input.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Kurumsal from './pages/Kurumsal.jsx';
import HomeContent from './components/Complex Components/HomeContent';
import Sektorler from './pages/Sektorler';
import Kariyer from './pages/Kariyer';
import Iletisim from './pages/Iletisim';
import Galeri from './pages/Galeri';
import LoginandSignUp from './pages/LoginandSignUp';
import Dashboard from './pages/Dashboard';
import HaberDetay from './pages/HaberDetay';
import SiteHaritasi from './components/Complex Components/SiteHaritası/SiteHaritasi';
import CerezPolitikasi from './components/Complex Components/CerezPolitikasi/CerezPolitikasi';
import KVKK from './components/Complex Components/KVKK/KVKK';
import GizlilikPolitikasi from './components/Complex Components/GizlilikPolitikasi/GizlilikPolitikasi';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeContent />}></Route>
        <Route path="/kurumsal" element={<Kurumsal />}></Route>
        <Route path="/sektorler" element={<Sektorler />}></Route>
        <Route path="/medya" element={<Galeri />}></Route>
        <Route path="/medya/:newsId" element={<HaberDetay />}></Route>
        <Route path="/kariyer" element={<Kariyer />}></Route>
        <Route path="/iletisim" element={<Iletisim />}></Route>
        <Route path="/admin" element={<LoginandSignUp />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/siteharitasi" element={<SiteHaritasi />}></Route>
        <Route path="/cerezpolitikasi" element={<CerezPolitikasi />}></Route>
        <Route path="/KVKK" element={<KVKK />}></Route>
        <Route path="/gizlilikpolitikasi" element={<GizlilikPolitikasi />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
