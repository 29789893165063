import React from 'react';
import image1 from '../../../assets/tfy-logo.png';
import image2 from '../../../assets/damasko-logo.png';
import image3 from '../../../assets/beyazıt-logo.png';
import image4 from '../../../assets/movel-logo.png';
import { Link } from 'react-router-dom';
import SectorCard from '../HomeContent/SectorCard';

const Firmalar = () => {
  return (
    <div className="grid grid-cols-1 gap-20 lg:gap-28">
      <div className="flex flex-col lg:flex-row gap-x-10 items-center">
        <div className="flex flex-col flex-1">
          <div className="flex gap-x-2 items-center">
            <div className="w-3 h-3 bg-primary"></div>
            <div className="text-lg font-semibold text-primary">
              Damasko Tekstil
            </div>
          </div>
          <div className="w-full h-px bg-primary my-2"></div>
          <div className="text-sm text-justify ">
            <div className="font-extrabold float-left p-2 mr-1 font-sans text-4xl border border-primaryHover text-primary">
              Y
            </div>{' '}
            ıllık 2 milyon metre döşemelik kumaş üretimi yapmakta olan ve
            yurtiçinde bayilik sistemiyle çalışan firmamız, yurtdışında ise
            Avrupa ve Ortadoğu ülkeleri başta olmak üzere birçok ülkeye ihracat
            yapmaktadır. Desenlerini kendi desen ünitelerinde dizayn etmekte,
            ayrıca müşteri istekleri doğrultusunda özel desen ve logalar da
            hazırlamaktadır. Kumaşlara abrasyon ve ışık haslığı gibi özel
            testler de uygulanmaktadır. Ürünlerimizin desenlerinde ve
            kalitesinde her zaman yaratıcılık ve farklılık ilke edinilmektedir.
            Ürünlerimizin desenleri, firmamız bünyesindeki desen ünitelerinde
            dizayn edilmekte, ayrıca müşteri istekleri doğrultusunda, özel desen
            ve logalar da hazırlanmaktadır. Ek olarak kumaşlara abrasyon ve ışık
            haslığı gibi özel testler de uygulanmaktadır.
          </div>
        </div>
        <SectorCard image={image2} />
      </div>

      <div className="flex flex-col lg:flex-row-reverse gap-x-10 items-center">
        <div className="flex flex-col flex-1">
          <div className="flex gap-x-2 items-center">
            <div className="w-3 h-3 bg-primary"></div>
            <div className="text-lg font-semibold text-primary">
              Beyazıt Engin İnşaat
            </div>
          </div>
          <div className="w-full h-px bg-primary my-2"></div>
          <div className="text-sm text-justify">
            <div className="font-extrabold float-left p-2 mr-1 font-sans text-4xl border border-primaryHover text-primary">
              B
            </div>{' '}
            eyazıt Engin İnşaat 1998’den günümüze dek dürüstlük ve istikrar
            politikalarını gözeterek yapmış olduğu başarılı çalışmalarla inşaat
            sektöründe kökleşmiş, örnek bir firmadır. Tevfik Engin İnşaat,
            özellikle konut ve ticari yaşam alanları konusunda uzmanlaşmış ve bu
            alanda inşaat sektörünün önde gelen firmalarından biri olmuştur.
            Firmamız; kurulduğu günden bu yana gerek yurt içinde gerekse yurt
            dışında fabrika ve konut inşaatı alanında uzmanlık isteyen
            çalışmalar için sonsuz özverisini günümüz teknolojisiyle
            birleştirerek mükemmel bir altyapı oluşturmuş, böylelikle günümüz
            koşullarına uyarlı rahatlığın ve konforun sınırsızca hissedildiği
            alanlar yaratmıştır. Zaman ve kalitenin çok önemli kavramlar
            olduğunu benimsemiş olan Tevfik Engin İnşaat, projelerini taahhüt
            ettiği zaman ve kalitede müşterilerine sunmak için tüm imkanlarıyla
            çalışmalarına devam etmektedir.
          </div>
        </div>
        <SectorCard image={image3} />
      </div>

      <div className="flex flex-col lg:flex-row gap-x-10 items-center">
        <div className="flex flex-col flex-1">
          <div className="flex gap-x-2 items-center">
            <div className="w-3 h-3 bg-primary"></div>
            <div className="text-lg font-semibold text-primary">
              Movell Yazılım
            </div>
          </div>
          <div className="w-full h-px bg-primary my-2"></div>
          <div className="text-sm text-justify ">
            <div className="font-extrabold float-left p-2 mr-1 font-sans text-4xl border border-primaryHover text-primary">
              M
            </div>{' '}
            ovell Yazılım, yazılım dünyasının öncü isimlerinden birisi olarak,
            işletmelerin dijital dönüşümüne öncülük eden bir ekip tarafından
            Damasko Holding bünyesinde 2022 yılında kurulmuştur. Müşterilerine
            dünya standartlarında yazılım çözümleri sunmak için kurulan
            firmamız, yenilikçi teknolojiler ve deneyimli bir ekiple, sektörün
            önde gelen firmaları arasında yer almaktadır. En güncel
            teknolojileri takip ederek yazılım geliştirme süreçlerini sürekli
            olarak iyileştiren ve müşterilerinin işlerini kolaylaştıran
            firmamız, toplumsal sorumluluğunun da bilincinde olarak, yazılım
            sektöründe çığır açılmasına katkı sağlayacak hizmetler sunmaya devam
            etmektedir.
          </div>
        </div>
        <SectorCard image={image4} />
      </div>

      <div className="flex flex-col lg:flex-row-reverse gap-x-10 items-center">
        <div className="flex flex-col flex-1">
          <div className="flex gap-x-2 items-center">
            <div className="w-3 h-3 bg-primary"></div>
            <div className="text-lg font-semibold text-primary">
              TFY Home Collection
            </div>
          </div>
          <div className="w-full h-px bg-primary my-2"></div>
          <div className="text-sm text-justify">
            <div className="font-extrabold float-left p-2 mr-1 font-sans text-4xl border border-primaryHover text-primary">
              G
            </div>{' '}
            eniş ürün yelpazemiz arasında, her tarza ve ihtiyaca uygun ev
            tekstil ürünleri ve daha pek çok ürün yer almaktadır. Tüm
            ürünlerimiz, kaliteli malzemelerden üretilerek, yüksek standartlarda
            üretim sürecinden geçmektedir. Bu sayede, müşterilerimize uzun
            ömürlü ve kullanışlı ürünler sunulmaktadır. En büyük önceliği
            müşteri memnuniyeti olan firmamızın müşterilerimizin ihtiyaçlarına
            ve isteklerine uygun çözümler sunmak için özenle seçilmiş, eğitimli
            ve donanımlı personeli her zaman hizmetinizdedir. Siparişleriniz
            zamanında ve güvenli bir şekilde teslim edilmekte, ayrıca herhangi
            bir sorun durumunda müşteri destek ekibimiz hızlı bir şekilde çözüm
            sunmaktadır.
          </div>
        </div>
        <SectorCard image={image1} />
      </div>
    </div>
  );
};

export default Firmalar;
