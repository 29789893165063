import React, { useEffect, useRef, useState } from 'react'
import Logo from './Logo'
import Navbar from './Navbar'
import Hamburger from './Hamburger'

const HeaderSticky = () => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
      const onScroll = () => setOffset(window.pageYOffset);
      
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div className={'bg-slate-200 fixed top-0 w-full shadow-xl opacity-80  hover:opacity-100 delay-150 transition-opacity z-[10] ' + (offset>100 ? 'block' : 'hidden')}>
        <div className='container flex items-center justify-between'>
            <div className='max-w-[10rem] my-1'>
            <Logo />
            </div>
            <Navbar offset={offset} />
            <Hamburger />
        </div>
    </div>
  )
}

export default HeaderSticky