import React from 'react';

const SectorCard = ({ image, title, description }) => {
  return (
    <div className="group lg:max-w-[18rem] lg:mx-auto rounded mx-2 sm:mx-0 overflow-hidden shadow-lg hover:shadow-xl bg-white transition-all ">
      <figure className="box-border overflow-hidden ">
        <img
          className="w-full lg:min-h-lg group-hover:scale-110 transition-all ease-in-out duration-500 hover:filter-none"
          src={image}
          alt={title}
        />
      </figure>

      
    </div>
  );
};

export default SectorCard;
