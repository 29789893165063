import React from 'react';
import { Link } from 'react-router-dom';
const OurStory = () => {
  return (
    <div className="bg-ourStory bg-cover bg-no-repeat bg-bottom  bg-fixed backdrop-blur-[.5] section-top-padding py-12">
      <div className="container">
        <div className="top-title-left mb-2 text-center text-sky-50">
          HİKAYEMİZ
        </div>
        <div className="main-title mb-5 text-center text-sky-50">
          KURULDUĞUMUZ GÜNDEN BERİ AYNI HEVESLE 
        </div>
        <div className="text-white text-center text-sm">
          2000 yılında Bursa'da kurulan Damasko Tekstil, o dönem Türkiye'de
          oldukça revaçta olan tekstil sektöründe faaliyet göstermek üzere
          kuruldu. Şirketin kurucusu Fatih ENGİN, sektördeki deneyimini ve
          tutkusunu iş hayatına yansıtarak, yaptığı başarılı çalışmalarla adını
          kısa sürede sayılı markalar arasına yazdırmayı başardı.
        </div>
        <div className="flex justify-center mt-5">
          <Link
            to="/kurumsal"
            className="text-xs px-8 py-2 border border-gray-100 text-white hover:text-primary rounded font-semibold bg-none transition-all hover:bg-gray-100"
          >
            Hikayeye Göz At
          </Link>
        </div>
        {/* <div className=" text-white flex gap-14 items-center justify-center">
          <div className="flex flex-col items-center justify-center hover:scale-125 transition-all cursor-pointer">
            <div className="text-6xl pb-1 ">
              <GiSewingString />
            </div>
            <div className="text-lg">Tekstil</div>
          </div>
          <div className="flex flex-col items-center justify-center hover:scale-125 transition-all cursor-pointer">
            <div className="text-6xl pb-1">
              <BsBuilding />
            </div>
            <div className="text-lg">İnşaat</div>
          </div>
          <div className="flex flex-col items-center justify-center hover:scale-125 transition-all cursor-pointer">
            <div className="text-6xl pb-1">
              <TbDeviceDesktopAnalytics />
            </div>
            <div className="text-lg">Yazılım</div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default OurStory;
