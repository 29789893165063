import React, { useRef, useState } from 'react';
import BundledEditor from './BundleEditor';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddNew = ({ isOpen, onClose }) => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState();
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onChange = (content) => {
    setBody(content);
  };

  const token = JSON.parse(localStorage.getItem('user'));
  
  const navigate = useNavigate();
  const toast = useToast();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('Title', title);
      formData.append('Body', body);
      formData.append('Image', image);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Site: 'DamaskoHolding',
        },
      };

      const response = await axios.post(
        'https://api.damaskoholding.com/api/Blog/Add',
        formData,
        config
      );
      if (response.data) {
        setSubmitted(true);
        toast({
          title: 'Haber Eklendi !',
          description: 'Eklenen haberi görüntülemek için sayfayı yenileyin...',
          status: 'success',
          variant: 'solid',
          duration: 9000,
          position: 'top-right',
          isClosable: true,
        });
        await onClose();
      }
    } catch (error) {
      toast({
        title: error.message,
        status: 'error',
        variant: 'solid',
        duration: 9000,
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Bundled Editor
  const editorRef = useRef(null);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      blockScrollOnMount={false}
    >
      <ModalOverlay
        bg="blackAlpha.50"
        backdropFilter="blur(5px) hue-rotate(90deg)"
      />
      <ModalContent padding={5} bgColor="gray.200">
        <ModalHeader p={0}>Haber Ekle</ModalHeader>
        <ModalCloseButton />

        <section className="mt-3 text-sm">
          <form onSubmit={onSubmit} className="flex flex-col text-left">
            <label htmlFor="Title" className="mb-2">
              Haber Başlığı
            </label>
            <input
              className="text-gray-900 border border-gray-400 px-4 py-2 rounded-md"
              type="text"
              name="Title"
              id="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <label htmlFor="Body" className="my-2">
              Haber İçeriği
            </label>

            {/* Bundle EdITOR */}
            <BundledEditor
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue="<p>Haber içeriği giriniz...</p>"
              onEditorChange={onChange}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist',
                  'anchor',
                  'autolink',
                  'help',
                  'image',
                  'link',
                  'lists',
                  'searchreplace',
                  'table',
                  'wordcount',
                ],

                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />

            <label htmlFor="file" className="mt-3">
              Haber Gorseli Ekle
              <Alert status="warning">
                <AlertIcon />
                <div className="flex flex-col">
                  <AlertTitle>Dikkat!</AlertTitle>
                  <AlertDescription>
                    Yüklenilen resmin boyutu 1mb'tan fazla
                    olmamalıdır.JPG,PNG,JPEG ve SVG olarak yükleme
                    yapabilirsiniz...
                  </AlertDescription>
                </div>
              </Alert>
            </label>
            <input
              className="text-gray-300 my-5 rounded-md"
              id="file"
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
            />

            <Button
              className="bg-slate-400 hover:bg-slate-300 py-4 inline-block outline-none"
              
              type="submit"
              disabled={submitted}
            >
              {loading ? (
                <Spinner size="sm" />
              ) : (
                <p>{!submitted ? 'Haber Ekle' : 'Haber Eklendi'}</p>
              )}
            </Button>
          </form>
        </section>
      </ModalContent>
    </Modal>
  );
};

export default AddNew;
