import React from 'react';
import breadcrumbimg from '../../assets/breadcrumb.jpg';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

const BreadcrumbSec = ({ Current }) => {
  return (
    <div>
      <img src={breadcrumbimg} alt="" className="w-full " />
      <div className="bg-gray-200 py-3">
        <div className="container">
          <Breadcrumb fontWeight="medium" fontSize="sm">
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Anasayfa</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="/404">{Current}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbSec;
