import React from 'react'

const CerezPolitikasi = () => {
  return (
    <div className='container mt-16'>
    <h1 className='text-center font-bold my-5'>Damasko Holding Çerez Politikası</h1>
        
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id gravida dolor, in sagittis mauris. Etiam non leo quis dolor elementum varius quis sit amet ex. Fusce mattis mauris a tellus ultricies, vitae fermentum mauris ultrices. Cras vel nibh tortor. Phasellus libero risus, posuere euismod convallis ullamcorper, pulvinar ac magna. Morbi pharetra nunc auctor efficitur convallis. Sed ut massa egestas, lobortis justo sit amet, interdum eros.

    Nullam mollis libero id nulla placerat auctor. Nunc a mauris quis erat eleifend dignissim at eu sem. Duis eu arcu urna. Vivamus quis odio mi. Curabitur fringilla a ante ut maximus. Phasellus pulvinar ullamcorper nisi id pretium. Ut vitae nisl egestas, consequat tellus quis, commodo mauris. Cras et ante purus. Nam bibendum suscipit urna, vitae blandit massa tempus nec. Curabitur eget accumsan leo. Vivamus interdum sit amet felis quis dapibus. Donec rhoncus ullamcorper lacus nec dictum. Maecenas fringilla malesuada nisi, ornare faucibus sapien congue ut. Sed aliquet ultricies auctor. Pellentesque gravida nibh quis lectus fringilla euismod.
    
    Vivamus elementum elementum lacus quis mattis. Integer euismod leo felis. In hac habitasse platea dictumst. Donec lobortis facilisis vulputate. Vivamus sodales sodales arcu, quis cursus justo varius quis. Sed vel metus non mauris ultrices faucibus sed nec massa. Curabitur ut congue magna. Mauris commodo velit a vestibulum tristique. Proin quis quam quis neque interdum dictum. Sed sit amet nulla non magna facilisis hendrerit. Quisque commodo mauris ut pretium pellentesque. Donec facilisis diam semper sapien porta, id tincidunt metus varius. Nunc vitae tellus in urna faucibus tristique. Curabitur laoreet magna et dictum finibus. Mauris congue ut magna vitae volutpat.
    
    Cras eros purus, blandit in mauris in, viverra vulputate tellus. Etiam lobortis lacinia libero at sagittis. Sed tristique odio in mollis volutpat. Nunc in ex laoreet purus cursus tempus. Curabitur eu dui ut orci suscipit accumsan non non arcu. Fusce fermentum, urna a sagittis rhoncus, mi odio pharetra tortor, id ultricies nisi nisl ac dui. Mauris scelerisque arcu justo, vel efficitur nunc lacinia nec. Proin ut venenatis est. Nunc a ex lobortis justo venenatis mollis. Sed ut mi sem. Proin feugiat congue dignissim. Nam porttitor odio augue, sollicitudin molestie risus sollicitudin at. Morbi pulvinar eu ligula quis pharetra.
    
    Suspendisse scelerisque malesuada pellentesque. Phasellus iaculis odio id neque feugiat dignissim. Donec mattis finibus risus consequat finibus. Aliquam eget est luctus, varius mauris sed, mattis magna. Mauris pretium maximus mauris in ultricies. Nunc lobortis diam pretium arcu vehicula pulvinar. Nam ornare odio ipsum. Maecenas elementum luctus magna ac interdum. Pellentesque luctus diam in magna porta aliquet. Praesent non sapien aliquam, efficitur nibh vel, blandit turpis. Mauris vel massa a urna suscipit lobortis eget quis nunc. Fusce sed fringilla ante, nec lobortis ipsum.
    
    Fusce consectetur efficitur dui vitae vestibulum. Nam purus massa, imperdiet quis elementum in, tristique id eros. Nulla nec tempor risus. Proin a pretium mi. Sed auctor orci lectus. Fusce elementum finibus elit. Pellentesque semper tortor nec mi tempus, eu tempor enim faucibus. Suspendisse nulla metus, sodales eu ullamcorper id, dapibus et sem. Vivamus tortor odio, tempor ac fringilla vitae, imperdiet et nulla.
    
    Phasellus dapibus imperdiet ante a eleifend. Nullam bibendum auctor tortor et suscipit. Cras blandit velit vel elit vestibulum, in rhoncus mi auctor. Etiam hendrerit aliquet lacinia. Aliquam dapibus dapibus urna in efficitur. Suspendisse potenti. In fermentum pharetra nulla, eu egestas libero auctor eu. Nunc sollicitudin ex dignissim quam ornare, ut fermentum arcu consectetur.
    
    Aliquam auctor tempus iaculis. In ut interdum tellus. Pellentesque sodales laoreet nisl, non dignissim nibh vulputate tincidunt. Pellentesque ac luctus massa. Sed vitae sapien sit amet felis eleifend mollis. Morbi euismod, sem vitae sagittis sagittis, quam nulla laoreet sem, ac feugiat ipsum nibh eu enim. Integer porta gravida pulvinar. Curabitur id ante eget libero ullamcorper dignissim. Sed nisl mauris, feugiat in enim non, cursus faucibus augue. Cras semper sollicitudin purus ut aliquet. Maecenas efficitur, sapien a vulputate lacinia, sem ipsum dictum mauris, at eleifend ante ante a tellus. Nam quis erat orci. Donec vestibulum sed lectus sit amet posuere.
    
    Suspendisse sagittis nulla nec felis venenatis, ut consequat mauris sollicitudin. Sed imperdiet nisi at ornare lobortis. Ut tincidunt accumsan ante vel volutpat. Morbi laoreet ipsum lacus, sed lobortis lorem interdum efficitur. Integer porttitor cursus felis, at feugiat diam rutrum suscipit. Praesent imperdiet dolor auctor, auctor dui id, tristique mi. Duis vulputate augue et interdum auctor. Morbi ante augue, congue vel nisl vel, gravida laoreet diam. Phasellus lectus orci, fringilla sed nisl a, auctor pharetra ex. Suspendisse condimentum at nisl ac elementum. In tellus ante, euismod vitae ultrices id, consectetur eu tellus. Quisque porttitor nibh turpis, in semper felis accumsan eu. Praesent tempor, tortor et dignissim efficitur, urna nunc aliquam tortor, quis ultricies turpis justo ornare enim. Nunc risus mi, ultrices eu ligula sit amet, hendrerit interdum tortor.
    
    Nunc dapibus felis vitae erat cursus auctor. Nullam gravida, ligula id accumsan egestas, orci elit molestie urna, in viverra sem nisl eu odio. Sed gravida ligula lectus, eu varius erat pretium vel. Nulla id metus lacus. Sed tempor nulla eget rutrum elementum. Praesent ipsum libero, facilisis quis dignissim a, laoreet at eros. Nam nulla arcu, condimentum ac bibendum non, fringilla eu nisl. Nam vel consectetur neque, porta fermentum justo. In faucibus urna nec mi rutrum, eget porta lectus fringilla. Vivamus a mi in massa tincidunt ultricies ac sed sem. Morbi bibendum odio et egestas auctor. Donec fringilla, libero non mollis rutrum, massa risus pulvinar quam, ut imperdiet elit sapien vel ipsum. Nullam turpis justo, cursus in lobortis vel, blandit a metus. Nulla tempus justo leo, eu tincidunt enim lacinia et.
    
    Cras iaculis at sem eget interdum. Nulla quis nisl eget diam convallis commodo eu facilisis elit. Sed blandit sem sed egestas consectetur. Cras vel mi diam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed vel elementum arcu, sed euismod tortor. Phasellus mattis rhoncus dolor at feugiat. Suspendisse nec dui ut eros convallis faucibus. Donec in mauris vel libero porttitor gravida ultricies nec lectus. Fusce at odio vel lacus egestas posuere. Pellentesque hendrerit, risus et egestas scelerisque, lacus eros rhoncus quam, maximus mattis ipsum.</div>
  )
}

export default CerezPolitikasi