import {
  Button,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Divider,
} from '@chakra-ui/react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { SiBlockchaindotcom } from 'react-icons/si';
import SocialButtons from '../../Basic Components/SocialButtons';
import { BsFillTelephoneFill } from 'react-icons/bs';

const Hamburger = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navbarItems = [
    {
      id: 1,
      name: 'Kurumsal',
      link: 'kurumsal',
    },
    {
      id: 2,
      name: 'Faaliyet Alanları',
      link: 'sektorler',
    },
    {
      id: 3,
      name: 'Medya',
      link: 'medya',
    },
    {
      id: 4,
      name: 'Kariyer',
      link: 'kariyer',
    },
    {
      id: 5,
      name: 'İletişim',
      link: 'iletisim',
    },
  ];

  return (
    <div className="block lg:hidden z-50">
      <Button colorScheme="gray" onClick={onOpen}>
        <GiHamburgerMenu />
      </Button>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Damasko Holding</DrawerHeader>
          <DrawerBody className="flex flex-col justify-between">
            <div className="flex flex-col">
              {navbarItems.map((navItem) => (
                <Link
                  key={navItem.id}
                  to={`/${navItem.link}`}
                  className="text-sm p-2  hover:bg-primary hover:text-white"
                >
                  <div className="flex items-center">
                    <div className="pr-2">
                      <SiBlockchaindotcom />
                    </div>{' '}
                    {navItem.name}
                  </div>
                </Link>
              ))}
            </div>

            <div className="border-t">
              <a
                href="https://goo.gl/maps/k8BvPXScTCPo4TQZ8"
                rel="noreferrer"
                target="_blank"
                className="text-xs text-gray-500"
              >
                Veysel Karani, Mah. Köşk Cad, <br /> 10. Lale Sk. No:3-5, 16090
                Osmangazi/Bursa
              </a>
              <br />
              <a href="tel:+902242158508" className="text-xs text-gray-500 ">
                0(224)215 85 08
              </a>
              <SocialButtons />
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default Hamburger;
