import React from 'react';
import image1 from '../../../assets/tfy-logo.png';
import image2 from '../../../assets/damasko-logo.png';
import image3 from '../../../assets/beyazıt-logo.png';
import image4 from '../../../assets/movel-logo.png';
import SectorCard from './SectorCard';
import MainButton from '../../Basic Components/MainButton';

const Sectors = () => {
  const sectors = [
    {
      title: 'TFY Home',
      image: image1,
    },
    {
      title: 'Damasko Tekstil',
      image: image2,
    },
    {
      title: 'Beyazıt Engin İnşaat',
      image: image3,
    },
    {
      title: 'Movell Yazılım',
      image: image4,
    },
  ];

  return (
    <div className="section-top-padding container flex flex-col lg:flex-row ">
      <div className="basis-2/4 lg:flex flex-col justify-center">
        <div className="top-title mb-2 ">MARKALAR</div>
        <div className="main-title mb-10 ">ŞİRKETLERİMİZ</div>
        <div className="text-[14px] lg:max-w-lg text-center lg:text-left mb-8 lg:mb-0">
          Her bir şirketiyle farklı sektörlerde öncü konumlarda yer alarak,
          müşteri memnuniyeti odaklı hizmet anlayışını benimseyen Damasko
          Holding iştirakları yurtiçinde ve 20'den fazla ülkede faaliyet
          göstermektedir. Şirketlerimiz, müşterilerine en iyi hizmeti sunmak
          adına sürekli olarak kendini geliştirmekte ve yeniliklere açık bir
          yaklaşım sergilemektedir. İşimizde sürdürülebilirlik, güvenilirlik ve
          kaliteye verdiğimiz önemle, müşterilerimizin beklentilerini en üst
          seviyede karşılamayı hedefliyoruz.
        </div>
        <div className="flex justify-center lg:inline-block  my-5  ">
          <a href="/sektorler"><MainButton text="Sektörleri İncele" /></a>
        </div>
      </div>
      <div className="basis-2/4 grid xl:grid-cols-2 sm:grid-cols-2 gap-5 ">
        {sectors.map((sector) => (
          <SectorCard
            title={sector.title}
            image={sector.image}
            description={sector.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Sectors;
