import React from 'react';
import damasko from '../../../assets/hikayemiz.svg';

const Hikayemiz = () => {
  return (
    <div>
      <h3 className="font-bold mb-5 text-xl">Hikayemiz</h3>
      <div>
        <img className="w-md float-right lg:m-1 lg:ml-5" src={damasko} alt="damasko" />

        <div className='text-justify text-sm'>
          <p className="indent-8 mb-3">
            2000 yılında Bursa'da kurulan Damasko Tekstil, o dönem Türkiye'de
            oldukça revaçta olan tekstil sektöründe faaliyet göstermek üzere
            kuruldu. Şirketin kurucusu Fatih ENGİN, sektördeki deneyimini ve
            tutkusunu iş hayatına yansıtarak, yaptığı başarılı çalışmalarla
            adını kısa sürede sayılı markalar arasına yazdırmayı başardı.
          </p>
          <div className="font-semibold py-1 mb-3 ">1910'DEN BUGÜNE</div>
          <p className="indent-8 mb-3">
            Behçet ENGİN'in 1910'da Ağrı'nın Doğubeyazıt ilçesinde ayakkabı
            imalatıyla başlayan bu serüven 1978 yılında Tevfik ENGİN tarafından
            devralınmıştır. 1990 yılında büyüyerek mevcut tesisi İstanbul’a
            taşımışlardır. 1995 yılında ayakkabı sektöründe devam ederken aynı
            anda inşaat sektöründe faaliyetler başlanmış ve kısa sürede sektörde
            adından söz ettiren faaliyetlere imza atılmıştır.
          </p>
          <div className="font-semibold py-1 mb-3 ">TEKSTİL FAALİYETLERİ</div>
          <p className="indent-10 mb-2">
            1998 yılı itibariyle ayakkabılıcık ve inşaat sektöründe yaşanan
            durgunluk sebebiyle şirket,faaliyetlerini tekstil alanına yöneltmiş
            ve Tevfik Engin Teks. Dok. San. Tic. A.Ş. kurulmuştur.Şirket
            faaliyetlerini Damasko Holding çatısı altında halen devam
            ettirmektedir.
          </p>
          <p className="indent-10 mb-2">
            Damasko Tekstil, kaliteli ürünleri ve müşteri memnuniyetine verdiği
            önemle sektörde öne çıkan bir marka haline geldi. Şirket, yurtiçinde
            ve yurtdışında gerçekleştirilen fuarlara katılarak ülkemizi temsil
            etti ve dünya piyasasında önemli bir konuma sahip oldu.Şu an 20'den
            fazla ülkeye ihracat yapmaktadır.
          </p>
          <p className="indent-10 mb-2">
            Damasko Tekstil'in başarısının arkasındaki en büyük etkenlerden
            biri, yenilikçi ve özgün ürünler geliştirmek için yapılan yatırımlar
            oldu. Şirket, müşterilerinin ihtiyaçlarını karşılayacak şekilde
            ürünlerini sürekli olarak geliştirerek sektördeki diğer firmalardan
            ayrıldı.
          </p>
          <p className="indent-10 mb-2">
            Fatih ENGİN, vizyonu ve liderlik özellikleri sayesinde şirketi
            başarıya taşıdı. Şirketin büyümesi ve gelişmesi için yaptığı
            stratejik hamlelerle, Damasko Holding bugün sektörde önde gelen
            kuruluşlar arasında yer almaktadır.
          </p>
          <p className="indent-10 mb-2">
            Bugün Damasko Holding, sadece tekstil sektöründe değil, birçok
            farklı sektörde faaliyet gösteren bir holding konumuna gelmiştir.
            Şirket, faaliyet gösterdiği tüm sektörlerde kalite ve müşteri
            memnuniyetine odaklanarak, başarılı çalışmalarına devam etmektedir.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hikayemiz;
