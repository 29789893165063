import React from 'react';
import { Link } from 'react-router-dom';
import image1 from '../../../assets/yazilim.jpg';
import image2 from '../../../assets/fabrika/61.jpg';
import image3 from '../../../assets/sektor-insaat.jpg'

const Faaliyet = () => {
  return (
    <div className="grid grid-cols-1 gap-20 lg:gap-28">
      <div className="flex flex-col lg:flex-row gap-x-10 items-center">
        <div className="flex flex-col">
          <div className="flex gap-x-2 items-center">
            <div className="w-3 h-3 bg-primary"></div>
            <div className="text-lg font-semibold text-primary">
              Tekstil Faaliyetleri
            </div>
          </div>
          <div className="w-full h-px bg-primary my-2"></div>
          <div className="text-sm text-justify ">
            <div className="font-extrabold float-left p-2 mr-1 font-sans text-4xl border border-primaryHover text-primary">
              Y
            </div>{' '}
            ıllık 2 milyon metre döşemelik kumaş üretimi yapmakta olan ve
            yurtiçinde bayilik sistemiyle çalışan firmamız, yurtdışında ise
            Avrupa ve Ortadoğu ülkeleri başta olmak üzere birçok ülkeye ihracat
            yapmaktadır.Desenlerini kendi desen ünitelerinde dizayn etmekte,
            ayrıca müşteri istekleri doğrultusunda özel desen ve logalar da
            hazırlamaktadır.Kumaşlara abrasyon ve ışık haslığı gibi özel testler
            de uygulanmaktadır.Ürünlerimizin desenlerinde ve kalitesinde her
            zaman yaratıcılık ve farklılık ilke edinilmektedir. Ürünlerimizin
            desenleri, firmamız bünyesindeki desen ünitelerinde dizayn
            edilmekte, ayrıca müşteri istekleri doğrultusunda, özel desen ve
            logalar da hazırlanmaktadır. Ek olarak kumaşlara abrasyon ve ışık
            haslığı gibi özel testler de uygulanmaktadır.
          </div>
        </div>
        <img
          src={image2}
          alt="tekstil"
          className="w-[400px] mx-auto mt-5 lg:mt-0"
        />
      </div>

      <div className="flex flex-col lg:flex-row-reverse gap-x-10 items-center">
        <div className="flex flex-col">
          <div className="flex gap-x-2 items-center">
            <div className="w-3 h-3 bg-primary"></div>
            <div className="text-lg font-semibold text-primary">
              İnşaat Faaliyetleri
            </div>
          </div>
          <div className="w-full h-px bg-primary my-2"></div>
          <div className="text-sm text-justify">
            <div className="font-extrabold float-left p-2 mr-1 font-sans text-4xl border border-primaryHover text-primary">
              D
            </div>{' '}
            amasko Holding bünyesinde yer alan, yurtiçinde tanınan bir inşaat
            şirketi olarak, yılların tecrübesi ve kalitesiyle müşterilerimize
            hizmet vermekteyiz. Vizyonumuz, inşa ettiğimiz yapılarla insanların
            hayatını kolaylaştırmak ve daha konforlu hale getirmektir. Bu
            doğrultuda, her zaman yenilikçi çözümler üretiyor ve
            müşterilerimizin taleplerine özel çözümler sunuyoruz. Misyonumuz,
            müşterilerimizin ihtiyaçlarını en iyi şekilde anlamak ve onlara en
            kaliteli hizmeti sunmak üzerine kuruludur. Yapılarımızın her
            aşamasında, en kaliteli malzemeleri kullanarak ve profesyonel
            ekiplerimizle çalışarak, müşterilerimize kaliteli ve güvenli yapılar
            inşa ediyoruz. Müşterilerimizin güvenliği ve konforu bizim için her
            zaman önceliklidir. Bu nedenle, inşa ettiğimiz yapıların her
            aşamasında, sıkı kalite kontrol mekanizmaları uyguluyor ve
            müşterilerimize en yüksek kalite standartlarında yapılar sunuyoruz.
          </div>
        </div>
        <img
          src={image3}
          alt="tekstil"
          className="w-[400px] mx-auto mt-5 lg:mt-0"
        />
      </div>

      <div className="flex flex-col lg:flex-row gap-x-10 items-center">
        <div className="flex flex-col">
          <div className="flex gap-x-2 items-center">
            <div className="w-3 h-3 bg-primary"></div>
            <div className="text-lg font-semibold text-primary">
              Yazılım Faaliyetleri
            </div>
          </div>
          <div className="w-full h-px bg-primary my-2"></div>
          <div className="text-sm text-justify ">
            <div className="font-extrabold float-left p-2 mr-1 font-sans text-4xl border border-primaryHover text-primary">
              M
            </div>{' '}
            üşterilerine en iyi kalitede yazılım ürünleri ile onların
            verimliliklerini artıracak yazılım çözüm hizmetleri sunan firmamızın
            en önemli hedefleri; sektörde yenilikler yaratmak, rekabet gücünü
            artırmak, çalışanlarının profesyonel gelişimini desteklemek ve sahip
            olduğu deneyimi iş geliştirme süreçlerinin dijitalleşmesini
            sağlayarak verimliliği artırmaktır. Bu nedenle; özel yazılım
            çözümleri sunarak, işletmelerin iş süreçlerini kolaylaştırıyor ve
            müşterilerimizin ihtiyaçlarına uygun özelleştirilmiş yazılım
            çözümleri sunarak onların ihtiyaçlarını karşılamaya çalışıyoruz. Tüm
            bu sahip olduğumuz deneyim ve bilgi birikimini teknolojik ürünlerle
            müşterilere sunarak alanında Türkiye’de ve uluslararası piyasada
            tanınan bir şirket olmak.
          </div>
        </div>
        <img
          src={image1}
          alt="tekstil"
          className="w-[400px] mx-auto mt-5 lg:mt-0"
        />
      </div>
    </div>
  );
};

export default Faaliyet;
