import { CountUp } from 'use-count-up';

const Numbers = () => {
  return (
    <div className='bg-primary '>
    <div className="container mx-auto px-2 sm:px-0 section-top-padding flex flex-col lg:flex-row flex-wrap rounded text-white p-8 ">
      <div className="flex flex-col justify-center items-center basis-1/4 border-b-2 lg:border-b-0 lg:border-r-2 pb-8 lg:pb-0">
        <div className="text-3xl font-bold mb-2  ">
          +<CountUp isCounting end={5000} duration={19.2} />
        </div>
        <div>Müşteri</div>
      </div>

      <div className="flex flex-col justify-center items-center basis-1/4  border-b-2 lg:border-b-0 lg:border-r-2 p-8 lg:p-0">
        <div className="text-3xl font-bold mb-2">
          +<CountUp isCounting end={350} duration={15.2} />
        </div>
        <div>Çalışan</div>
      </div>
      <div className="flex flex-col justify-center items-center basis-1/4 border-b-2 lg:border-b-0 lg:border-r-2 p-8 lg:p-0">
        <div className="text-3xl font-bold mb-2">
          +<CountUp isCounting end={25} duration={15.2} />
        </div>
        <div>Ülkede Faaliyet</div>
      </div>
      <div className="flex flex-col justify-center items-center basis-1/4  pt-8 lg:pt-0 ">
        <div className="text-3xl font-bold mb-2">
          +<CountUp isCounting end={500} duration={15.2} />
        </div>
        <div>Tamamlanmış Proje</div>
      </div>
    </div>
    </div>
  );
};

export default Numbers;
