import axios from 'axios';

const API_URL = 'https://api.damaskoholding.com/';

//Register User

const register = async (userData) => {
  const response = await axios.post('https://api.damaskoholding.com/api/Auth/Register', userData);

  if (response.data) {
    const token = response.data.data.accessToken
    localStorage.setItem('user', JSON.stringify(token));
  }
  return response.data;
};

//Login User

const login = async (userData) => {
  const response = await axios.post('https://api.damaskoholding.com/api/Auth/Login', userData);

  if (response.data) {
    const token = response.data.data.accessToken
    localStorage.setItem('user', JSON.stringify(token));
  }
  return response.data;
};

//Logout User

const logout = () => {
  localStorage.removeItem('user');
};
const authService = {
  register,
  logout,
  login
};

export default authService;
