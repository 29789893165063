import React from 'react';
import { Link } from 'react-router-dom';

export default function Navbar({ offset }) {
  const navbarItems = [
    {
      id: 1,
      name: 'Kurumsal',
      link: 'kurumsal',
    },
    {
      id: 2,
      name: 'Faaliyet Alanları',
      link: 'sektorler',
    },
    {
      id: 3,
      name: 'Medya',
      link: 'medya',
    },
    {
      id: 4,
      name: 'Kariyer',
      link: 'kariyer',
    },
    {
      id: 5,
      name: 'İletişim',
      link: 'iletisim',
    },
  ];
  return (
    <div className={"hidden lg:block " + (offset > 100 ? 'font-semibold' : 'font-medium')}>
      <div className="flex">
        {navbarItems.map((navItem) => (

          <Link key={navItem.id} to={`/${navItem.link}`} className="nav-link group">

            {navItem.name}
            <div className='bg-primary w-0 mx-auto h-px transition-all delay-150 group-hover:w-full'></div>
          </Link>
          
        ))}
      </div>
    </div>
  );
}
