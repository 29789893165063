import React from 'react'
import { SiBlockchaindotcom } from 'react-icons/si';

const ListItem = ({ Item1, Item2, Item3, Item4 }) => {
  return (
    <div>
        <ul className='text-sm mt-4'>
            <li className='my-2 hover:text-gray-400 transition-all cursor-pointer text-gray-500 flex items-center'><SiBlockchaindotcom /><a href='/cerezpolitikasi' target='_blank' className='ml-2'>{Item1}</a></li>
            <li className='my-2 hover:text-gray-400 transition-all cursor-pointer text-gray-500 flex items-center'><SiBlockchaindotcom /><a href='/gizlilikpolitikasi' target='_blank' className='ml-2'>{Item2}</a></li>
            <li className='my-2 hover:text-gray-400 transition-all cursor-pointer text-gray-500 flex items-center'><SiBlockchaindotcom /><a href='/siteharitasi' target='_blank' className='ml-2'>{Item3}</a></li>
            <li className='my-2 hover:text-gray-400 transition-all cursor-pointer text-gray-500 flex items-center'><SiBlockchaindotcom /><a href='/KVKK' target='_blank' className='ml-2'>{Item4}</a></li>
        </ul>
    </div>
  )
}

export default ListItem
