import React from 'react';
import damasko from '../../../assets/gallery/7.jpeg';
const Misyonumuz = () => {
  return (
    <div className='text-justify'>
      <h3 className="font-bold mb-5 text-xl">Misyonumuz</h3>
      <img className="lg:max-w-md w-sm float-right m-1 ml-5 shadow-md" src={damasko} alt="" />
      <div className='text-sm'>
      <p className="indent-5 mb-2">
        Damasko Holding, dünya piyasalarında öncü bir marka olmayı hedefleyen
        bir şirkettir. Misyonumuz, müşterilerimizin ihtiyaçlarını en iyi şekilde
        karşılayacak kaliteli ürünler üretmek ve sunmaktır. Bu doğrultuda,
        sektördeki yenilikleri takip ederek, özgün ürünler tasarlayarak,
        müşterilerimizin beklentilerini aşmayı amaçlıyoruz.
      </p>
      <p className="indent-5 mb-2">
        Müşteri memnuniyeti, Damasko Holding'in öncelikli hedeflerinden biridir.
        Bu nedenle, müşterilerimizin ihtiyaçlarını ve beklentilerini anlamak
        için sürekli olarak araştırma yapıyoruz. Bu sayede, müşterilerimize en
        uygun çözümleri sunarak, kaliteli ürünlerimizi en iyi şekilde sunuyoruz.
      </p>
      <p className="indent-5 mb-2">
        Holdingimiz, sadece sektördeki varlığıyla değil, toplumsal sorumluluk
        bilinciyle de ön plana çıkıyor. Çevre dostu ürünler üreterek, doğaya ve
        çevreye olan sorumluluğumuzu yerine getirmeyi hedefliyoruz. Ayrıca,
        toplumsal sorumluluk projelerine destek vererek, topluma faydalı
        çalışmalar yapmayı amaçlıyoruz.
      </p>
      <p className="indent-5">
        Damasko Holding, faaliyet gösterdiği tüm sektörlerde kalite ve müşteri
        memnuniyeti odaklı çalışmalarını sürdürerek, dünya genelinde tanınan bir
        marka olma hedefine doğru emin adımlarla ilerlemektedir.
      </p>
      </div>
    </div>
  );
};

export default Misyonumuz;
