import React from 'react';
import Home from './Home';
import BreadcrumbSec from '../components/Complex Components/BreadcrumbSec';

export default function Kariyer() {
  return (
    <Home>
      <BreadcrumbSec Current="Kariyer" />
      <section className="relative z-10 overflow-hidden bg-white py-20 lg:py-[120px]">
        <div className="container mx-auto">
          <div className="-mx-4 flex flex-col lg:flex-row justify-start">
            <div className="w-full px-4 lg:w-1/2 xl:w-6/12">
              <div className="mb-12 max-w-[570px] lg:mb-0">
                <span className="top-title pb-4 ">Kariyer</span>
                <h2 className="main-title mb-10 ">Yetenek ve Kariyer Yönetimi</h2>
                <p className="text-sm pb-2">
                  Farklı sektörlerde,her zaman daha iyisini hedefleyerek büyüyen{' '}
                  <strong>Damasko Holding A.Ş</strong> katma değer yaratan tüm
                  çalışanlarına,insan kaynakları politikası gereği sürekli
                  gelişim sağlayabileceği fırsatlar sunmaktadır.
                </p>
                <p className="text-sm">
                  Kurumsal değerleri göz önünde bulundurarak,bu değerlerin
                  savunucusu olan tüm çalışanlarına eşit fırsatlar sunan Damasko
                  Holding A.Ş,yine bu çerçevede insan kaynakları politikasını
                  geliştirmiştir.Farklı pozisyonlarda ve seviyelerde bu ailenin
                  bir parçası olmak için açık pozisyonlarımızı
                  inceleyebilir,insan kaynaklarıyla iletişime geçebilirsiniz.
                </p>
              </div>
            </div>
            <div className="w-full basis-1/2">
              <div className="font-semibold text-primary pb-2 text-center lg:text-left">
                Açık Pozisyonlar
              </div>
              <div className="w-full h-[2px] bg-primaryHover"></div>
              <p className="text-sm text-center lg:text-left p-4 lg:p-0 lg:pt-2">
                İçerik Güncelleniyor...
              </p>
            </div>
          </div>
        </div>
      </section>
    </Home>
  );
}
