import React from 'react';

import logo from '../../../assets/logo-damasko-1.png';
import { Link } from 'react-router-dom';
export default function Logo() {
  return (
    <Link to="/">
      <img src={logo} className="w-[10rem] lg:w-56 my-2" alt="logo" />
    </Link>
  );
}
