import React from 'react';
import Header from '../components/Complex Components/Header/Header.jsx';
import Footer from '../components/Complex Components/Footer';
import HomeContent from '../components/Complex Components/HomeContent';
import FooterBottom from '../components/Complex Components/FooterBottom';
import BottomFix from '../components/Complex Components/BottomFix';
import HeaderSticky from '../components/Complex Components/Header/HeaderSticky.jsx';

export default function Home( { children } ) {
  return (
    <div>
      <Header />
      <HeaderSticky />
      { children }
      <Footer />
      <FooterBottom />
      <BottomFix />
    </div>
  );
}
