import React from 'react';
import damasko from '../../../assets/isg.jpg';

const ISGPolitika = () => {
  return (
    <div>
      <h1 className="font-bold mb-5 text-xl">
        İş Sağlığı ve Güvenliği Politikamız
      </h1>
      <div className='text-justify text-sm'>
        <img className="w-md float-right m-1 ml-5" src={damasko} alt="" />
        <p className="indent-5 mb-3">
          Damasko Holding olarak, tüm faaliyetlerimizde çalışanlarımızın sağlığı
          ve güvenliği bizim için en önemli önceliklerden biridir. Bu nedenle,
          iş güvenliği ve iş sağlığına büyük önem veriyoruz. Amacımız, tüm
          çalışanlarımızın iş güvenliği konusunda bilinçli olmalarını sağlamak
          ve herhangi bir iş kazasının önüne geçmek için gerekli tedbirleri
          alarak çalışmalarımızı sürdürmektir.
        </p>
        <div className="font-semibold py-1">ÖNCE İNSAN</div>
        <p className="indent-5 mb-2">
          Bu amaç doğrultusunda, iş güvenliği konusunda uygun eğitimleri vermek
          için çaba gösteriyoruz. Tüm çalışanlarımıza, işyerindeki risklerin
          farkında olmalarını ve uygun koruyucu ekipmanları kullanarak
          kendilerini korumalarını sağlamak için eğitimler veriyoruz. Ayrıca,
          tüm çalışanlarımızın iş güvenliği politikalarımıza uymalarını
          sağlayarak, iş kazalarının önlenmesi için gerekli olan disiplini
          sağlıyoruz.
        </p>
        <p className="indent-5 mb-2">
          Damasko Holding olarak, iş güvenliği konusunda kanunlar ve
          yönetmeliklerin gerektirdiği tüm standartlara uyuyoruz. İşyerimizde
          herhangi bir acil durumda hızlı ve etkili bir şekilde müdahale
          edebilmek için acil durum ekipmanlarına sahibiz. Ayrıca, tüm
          çalışanlarımızın iş güvenliği konusunda önerilerini ve görüşlerini
          dikkate alarak, sürekli olarak iş güvenliği politikalarımızı ve
          uygulamalarımızı gözden geçiriyoruz.
        </p>
        <p className="indent-5 mb-2">
          Damasko Holding, tüm çalışanlarına sağlıklı ve güvenli bir çalışma
          ortamı sağlamak için gerekli tüm adımları atarak, iş güvenliği
          konusunda en üst seviyede bir performans göstermeyi hedefliyoruz. Bu
          sayede, çalışanlarımızın kendilerini güvende hissetmelerini ve
          işlerine odaklanmalarını sağlıyoruz.
        </p>
      </div>
    </div>
  );
};

export default ISGPolitika;
