import React from 'react';
import SocialButtons from '../Basic Components/SocialButtons';
import Logo from '../Complex Components/Header/Logo';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { GrMail } from 'react-icons/gr';
import { FaRegBuilding } from 'react-icons/fa';
import ListItem from '../Basic Components/ListItem';

export default function Footer() {
  return (
    <div className="bg-gray-200 pt-8 -z-50 ">
      <div className="container flex flex-col md:flex-row md:items-center lg:items-start py-4 gap-8">
        <div className="basis-2/5 ">
          <Logo />
          <p className="my-4 mt-6 text-sm mr-12 text-gray-500">
            Faaliyet gösterdiği tüm sektörlerde kalite ve müşteri memnuniyeti
            odaklı çalışmalarını sürdürerek, dünya genelinde tanınan bir marka
            olma hedefine doğru emin adımlarla ilerliyoruz.
          </p>
          <SocialButtons />
        </div>
        <div className="basis-3/5 flex flex-col lg:flex-row justify-around lg:mt-8">
          <div>
            <p className="font-semibold text-md text-gray-500">
              Kısayollar
            </p>

            <ListItem
              Item1="Hikayemiz"
              Item2="Haberler"
              Item3="Medya"
              Item4="ISG Politikamız"
            />
          </div>
          <div>
            <p className="font-semibold text-md text-gray-500">
              Faydalı Linkler
            </p>


            <ListItem
              Item1="Çerez Politikası"
              Item2="Gizlilik Politikası"
              Item3="Site Haritası"
              Item4="KVKK"
            />
          </div>
          <div>
            <p className="font-semibold text-md text-gray-500">İletişim</p>

            <ul className="py-4 text-sm text-gray-500">
              <li className="mb-3">
                <div className="inline-block mr-2">
                  <BsFillTelephoneFill sx={{ fontSize: 18 }} />
                </div>
                <a href="tel:+902242158508">0(224)215 85 08</a>
              </li>
              <li className="mb-3">
                <div className="inline-block mr-2">
                  <GrMail sx={{ fontSize: 18 }} />
                </div>
                <a href="mailto:damasko@damaskoholding.com">
                  damasko@damaskoholding.com
                </a>
              </li>
              <li className="mb-3">
                <div className="inline-block mr-2">
                  <FaRegBuilding sx={{ fontSize: 18 }} />
                </div>
                <a
                  href="https://goo.gl/maps/k8BvPXScTCPo4TQZ8"
                  rel="noreferrer"
                  target="_blank"
                >
                  Veysel Karani, Mah. Köşk Cad,  <br/> 10. Lale Sk. No:3-5, 16090 Osmangazi/Bursa
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
