import React from 'react';
import CaroulselGaleri from './CaroulselGaleri';
import { fuarImg, tekstilImg } from './data';

const GaleriContent = () => {
  return (
    <div>
      <h3 className="font-bold mb-4 text-xl">Fuar ve Etkinlikler</h3>
      <p className='mb-4 text-sm'>
        Damasko Holding her yıl,yurtiçi veya yurtdışında düzenlenen onlarca fuara katılmakta yine aynı minvalde
        çeşitli kültür etkinliklerinde boy göstermektedir.İçeriğimiz zamanla güncellenmektedir. 
      </p>
      <CaroulselGaleri slides={fuarImg} />
      <h3 className="font-bold my-4 text-xl">Damasko Tekstil</h3>
      <p className='mb-4 text-sm'>
        Damasko Tekstil 2000'li yılların başlarından beri kaliteli üretim mottosuyla sektörde yer alıp gün geçtikçe müşteri portföyünü genişletmiştir.
        Ürünlerimizden bir kısmını buradan inceleyebilirsiniz...
      </p>
      <CaroulselGaleri slides={tekstilImg} />
    </div>
  );
};

export default GaleriContent;
