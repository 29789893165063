import React from 'react';
import MainButton from '../../Basic Components/MainButton';
import { Link } from 'react-router-dom';
import fabrika1 from '../../../assets/fabrika-4.jpg';
import fabrika2 from '../../../assets/fabrika-1.jpg';
import fabrika3 from '../../../assets/fabrika-2.jpg';
import fabrika4 from '../../../assets/fabrika-3.jpg';

const AboutUs = () => {
  return (
    <div className="container items-center lg:content-between sm:justify-between mx-auto grid lg:grid-cols-2 sm:gap-x-5 pt-20 grid-cols-1">
      <div className="flex flex-col justify-center px-4 sm:px-0 lg:pr-10 xl:pr-16">
        <div className="top-title mb-2 ">Kurumsal</div>
        <div className="main-title mb-10">HAKKIMIZDA</div>
        <p className="text-sm text-center lg:text-left">
          2000 yılında Fatih ENGİN tarafından kurulan Damasko Holding,
          inşaat, tekstil ve dijital alanlarda faaliyet
          göstermektedir.
        </p>
        <p className="text-lg font-medium my-2 text-center lg:text-left">
          1910'DEN BUGÜNE
        </p>
        <p className="text-sm mb-5 text-center lg:text-left">
          Behçet ENGİN'in 1910'da Ağrı'nın Doğubeyazıt ilçesinde ayakkabı imalatıyla
          başlayan bu serüven 1978 yılında Tevfik ENGİN tarafından devralınmıştır.
        </p>
        <Link className="flex justify-center lg:justify-start" to="/kurumsal">
          <MainButton text="Daha Fazlası" />
        </Link>
      </div>

      <div className="flex gap-3 px-4 lg:px-0 pt-12 lg:pt-0 justify-center">
        <div className="flex flex-col gap-3">
          <img className="shadow-xl" src={fabrika1} alt="aboutus" />
          <img className="shadow-xl" src={fabrika2} alt="aboutus" />
        </div>
        <div className="flex flex-col gap-3">
          <img className="shadow-xl" src={fabrika3} alt="aboutus" />
          <img className="shadow-xl" src={fabrika4} alt="aboutus" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
