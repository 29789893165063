import React from 'react';
import damasko from '../../../assets/gallery/6.jpeg';

const Vizyonumuz = () => {
  return (
    <div>
      <h3 className="font-bold mb-5 text-xl">Vizyonumuz</h3>
      <div className='text-justify text-sm'>
      <img className="lg:max-w-md w-sm float-right m-1 ml-5 shadow-md" src={damasko} alt="damasko" />
        <p className="indent-4 mb-3">
          Ülkemizdeki üretim kapasitesini artırarak, ülke insanına daha fazla
          istihdam sağlamak ve bu sayede hem ithalatımızı azaltıp hem de
          ihracatımızı artırarak ülke ekonomimize katkıda bulunmak en büyük
          hedefimizdir. Bu hedef doğrultusunda, teknolojik gelişmeleri yakından
          takip ederek, üretim süreçlerimizi sürekli olarak yenilemek ve
          modernize etmek için çaba göstermekteyiz.
        </p>
        <div className="font-semibold py-1 mb-3">GÜÇLÜ EKONOMİ VE GÜÇLÜ TÜRKİYE İÇİN</div>
        <p className="indent-4 mb-2">
          Ülkemizin güçlü ve dinamik bir ekonomiye sahip olması için, özellikle
          tekstil, inşaat ve dijital faaliyetler yürüttüğümüz yazılım gibi
          sektörlerde üretimimizi artırarak ülke ekonomimizin büyümesine katkı
          sağlamayı hedefliyoruz. 
        </p>
        <p className="indent-4 mb-2">Bunun yanı sıra, üretim süreçlerimizi çevreye
          duyarlı hale getirerek, sürdürülebilir bir gelecek için de
          çalışacağız. Ülkemizin genç ve dinamik nüfusu ile birlikte, özverili
          çalışmalarımızla ülkemizi daha da ileriye taşıyacağız. Üretim
          kapasitemizi artırarak, daha fazla istihdam yaratarak, ithalatımızı
          azaltarak ve ihracatımızı artırarak, ülkemizin ekonomik kalkınmasına
          katkıda bulunacağız.</p>
        <p className="indent-4 mb-2">
          Bu vizyon doğrultusunda, ülke genelindeki tüm sektörlerle iş birliği
          içinde çalışarak, ülkemizin ekonomik gücünü artıracağız. Ülkemizin
          güçlü bir geleceğe sahip olması için, hep birlikte çalışarak, ülkemizi
          daha ileriye taşıyacağız.
        </p>
      </div>
    </div>
  );
};

export default Vizyonumuz;
