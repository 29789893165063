import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Home from './Home';
import BreadcrumbSec from '../components/Complex Components/BreadcrumbSec';
import { Spinner } from '@chakra-ui/react';
import { BsArrowLeft } from 'react-icons/bs';
import HTMLReactParser from 'html-react-parser';
import SocialButtons from '../components/Basic Components/SocialButtons';

const HaberDetay = () => {
  const { newsId } = useParams();
  const [single, setSingle] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const getSingleNews = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `https://api.damaskoholding.com/api/Blog/GetById?id=${newsId}`
      );

      const data = res.data.data;
      setSingle(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleNews();
  }, []);

  if (loading) {
    <div>
      <Spinner />
    </div>;
  }

  return (
    <React.Fragment>
      <Home>
        <BreadcrumbSec Current={`Medya /${' '}${single.title}`} />
        <div className="container ">
          {loading ? (
            <div className="flex h-screen w-full justify-center items-center">
              {' '}
              <Spinner size='xl' />
            </div>
          ) : error ? (
            <div>
              {error === 'Network Error'
                ? 'Sunucu kaynaklı bir hata oluştu'
                : error}
            </div>
          ) : (
            <div className="flex flex-col lg:flex-row items-start my-8">
              <Link
                to="/medya"
                className="px-12 py-2 bg-gray-300 text-sm font-semibold flex items-center w-full lg:max-w-[10rem] justify-center mb-5 lg:mb-0"
              >
                <BsArrowLeft className="mr-3" /> Geri Dön
              </Link>
              <div className="pl-5">
                <div className="uppercase font-bold text-2xl mb-3">
                  {single.title}
                </div>
                <div className="text-sm text-gray-400">
                  {' '}
                  {new Date(single.createdDate).toLocaleString('tr-TR')}
                </div>
                <div >
                  <div className=''>
                    <img
                      src={single.imageUrl}
                      alt={single.title}
                      className="float-right lg:max-w-[490px] mx-auto shadow-md ml-4 mb-4"
                    />
                  </div>
                  <div className='text-sm text-justify'>{HTMLReactParser(`${single.body}`)}</div>
                </div>
              </div>
            </div>
          )}
          <div className="w-full h-px bg-gray-500 mt-24 mb-3"></div>
          <div className="flex items-center justify-end mb-2">
            <div className="text-xs text-gray-400 mr-2">Haberi Paylaş:</div>
            <SocialButtons />
          </div>
        </div>
      </Home>
    </React.Fragment>
  );
};

export default HaberDetay;
