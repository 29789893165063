import React, { useEffect, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { Skeleton, Spinner } from '@chakra-ui/react';
import parse from 'html-react-parser';
import axios from 'axios';

const TabNews = () => {
  const [openTab, setOpenTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState('');

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.damaskoholding.com/api/Blog/GetList`
      );
      const { data } = response.data;
      setBlogs(data);
    } catch (error) {
      setError(error.response);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container mx-auto section-top-padding">
        <div className="flex justify-between mb-3">
          <h2 className="main-title">Haberler & Duyurular</h2>
          <h5>
            <Link
              to="/medya"
              className="text-[13px] flex items-center hover:text-stone-400 transition-all ease-in-out"
            >
              <p className="pr-2 font-semibold">Tüm Haberler</p>
              <div>
                <BsArrowRight />
              </div>
            </Link>
          </h5>
        </div>
        <div className="flex gap-x-2 flex-col lg:flex-row-reverse ">
          <ul className="flex flex-col basis-1/3 list-none" role="tablist">
            {loading ? (
              <Skeleton />
            ) : (
              blogs.slice(0, 5).map((tabElement, i) => (
                <li
                  key={i}
                  className="mr-0 flex-auto text-start hover:bg-stone-100"
                >
                  <div
                    className={
                      'text-xs font-bold text-gray-500 uppercase px-5 py-[1.35rem] shadow-lg block leading-normal cursor-pointer ' +
                      (openTab === i + 1
                        ? 'text-white bg-stone-400'
                        : 'text-stone-400 bg-white')
                    }
                    onClick={() => setOpenTab(i + 1)}
                    data-toggle="tab"
                    role="tablist"
                  >
                    <div className='flex justify-between'>
                      <div>
                        <div className="text-start text-[10px]">
                          {new Date(tabElement.createdDate).toLocaleString(
                            'tr-TR'
                          )}
                        </div>
                        <div className="py-2">{tabElement.title}</div>
                      </div>
                      <div className="flex items-center justify-end hover:text-stone-300 mb-5 lg:hidden">
                        <a
                          href={`/medya/${tabElement.id}`}
                          className=" hover:text-stone-300 transition-all ease-in-out text-xs mr-2 "
                        >
                          Ayrıntıları İncele
                        </a>
                        <BsArrowRight />
                      </div>
                    </div>
                  </div>
                </li>
              ))
            )}
          </ul>

          <div className="relative flex-col min-w-0 break-words border-stone-400 w-full shadow-md hidden lg:flex">
            <div className="px-4 py-5 flex-auto border">
              <div className="max-h-[490px] overflow-auto text-ellipsis ">
                {blogs.map((tabElement, index) => (
                  <div
                    key={index}
                    className={openTab === index + 1 ? 'block' : 'hidden'}
                    id="link"
                  >
                    <div className="w-full">
                      <img
                        src={tabElement.imageUrl}
                        alt={tabElement.title}
                        className="h-full max-w-[400px] ml-auto float-left mr-5 mb-5 over"
                      />
                      <div className="">
                        <p className="text-sm font-regular text-primary ">
                          {new Date(tabElement.createdDate).toLocaleString(
                            'tr-TR'
                          )}
                        </p>
                      </div>

                      <a href={`/medya/${tabElement.id}`}>
                        <p className="font-semibold pb-2 uppercase text-lg overflow-hidden">
                          {tabElement.title}
                        </p>
                      </a>
                      <p className="text-sm">{parse(tabElement.body)}</p>
                      <Link to={`/medya/${tabElement.id}`}>
                        <div className="py-3 font-semibold text-sm mt-5 underline transition-all">
                          Detayları İncele
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabNews;
