import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import bareli_abla from "../../../assets/slide/bareli-abla.webp";
import bareli_mob from "../../../assets/slide/bareli-mob.webp";
import tree from "../../../assets/slide/tree-slide.webp";
import tree_mob from "../../../assets/slide/tree-mob.webp";
import hometex from "../../../assets/slide/hometex.webp";
import hometex_mob from "../../../assets/slide/hometex-mobil.webp";
import { useEffect, useState } from "react";

export default function Slider() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slides = [
    {
      id: 1,
      img: isMobile ? bareli_mob : bareli_abla,
      title: 'Yarını Bugünden İnşa Ediyoruz',
      desc: 'Sıkı kalite kontrol mekanizmaları uyguluyor ve müşterilerimize en yüksek kalite standartlarında yapılar sunuyoruz.',
      buttonText: 'Göz At',
    },
    {
      id: 2,
      img: isMobile ? tree_mob : tree,
      title: 'Temiz Bir Gelecek İçin Çalışıyoruz',
      desc: 'Çevre dostu ürünler üreterek, doğaya ve çevreye olan sorumluluğumuzu yerine getirmeyi hedefliyoruz.',
      buttonText: 'Incele',
    },
   
    {
      id: 4,
      img: isMobile ? hometex_mob : hometex,
      
    },
  ];

  return (
    <div className="">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        freeMode={true}
        pagination={{ clickable: true }}
        slidesPerView={1}
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        autoplay={true}
      >
        {slides.reverse().map((slide, key) => (
          <SwiperSlide>
            <img
              src={slide.img}
              alt="bareli abla"
              className="w-full brightness-[.95]"
              loading="lazy"
            />
            <div className="absolute top-[30%] right-0 left-0 flex flex-col items-center z-10 text-white text-center lg:text-left">
              <p className="text-lg lg:text-3xl mb-2 font-bold">
                {slide.title}
              </p>
              <p className="mb-4 max-w-xl text-center lg:text-sm">
                {slide.desc}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
