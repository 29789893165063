import React, { useEffect } from 'react';
import {
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import Logo from '../components/Complex Components/Header/Logo';
import DashboardNews from '../components/Complex Components/Dashboard.jsx/DashboardNews';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AddNew from '../components/Complex Components/Dashboard.jsx/AddNew';
import AddSlayt from '../components/Complex Components/Dashboard.jsx/AddSlayt';
import { AiOutlinePlus } from 'react-icons/ai';
import { logout } from '../features/auth/authSlice';
import DashboardSlides from '../components/Complex Components/Dashboard.jsx/DashboardSlides';

const Dashboard = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const toast = useToast();
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user) {
      navigate('/admin');
    } 
    
  }, [user]);

  // const { isOpen, onOpen, onClose } = useDisclosure();

  const modal1 = useDisclosure()
  const modal2 = useDisclosure()

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <div className="w-[70%] mx-auto flex justify-between items-center">
        <div className="max-w-[10rem]">
          <Logo />
        </div>
        <div className="text-sm p-4 border border-gray-500 bg-gray-300 text-gray-900 font-semibold cursor-pointer rounded" onClick={() => dispatch(logout())}>
          Çıkış Yap
        </div>
      </div>
      <div className="w-[70%] mx-auto mt-10">
        <div>
          <div
            className="text-md mb-12 rounded p-3 bg-slate-200 text-gray-900 font-semibold inline-block cursor-pointer"
            onClick={modal1.onOpen}
          >
            <div className="flex items-center ">
              <AiOutlinePlus /> <div> Yeni Haber Ekle</div>
            </div>
          </div>
          <div
            className="text-md mb-12 ml-3 rounded p-3 bg-slate-200 text-gray-900 font-semibold inline-block cursor-pointer"
            onClick={modal2.onOpen}
          >
            <div className="flex items-center ">
              <AiOutlinePlus /> <div> Slayt Ekle</div>
            </div>
          </div>
          <AddNew isOpen={modal1.isOpen} onClose={modal1.onClose} />
          <AddSlayt isOpen={modal2.isOpen} onClose={modal2.onClose} />

          <h3 className="text-3xl mb-5 font-semibold underline">
            Tüm Haberler
          </h3>
          <DashboardNews />

          
          <h3 className="text-3xl mb-5 font-semibold underline mt-4">
            Tüm Slaytlar
          </h3>
          <DashboardSlides />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
