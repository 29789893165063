import React from 'react'

const FooterBottom = () => {
    const today = new Date();
    const year = today.getFullYear();
  return (
    <div>
      <p className="container mx-auto text-center text-gray-400 text-xs py-2">
        © {year} Damasko Holding.Tüm Hakları Saklıdır.
      </p>
    </div>
  )
}

export default FooterBottom