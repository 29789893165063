import React from 'react';

const Videolar = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 justify-items-center gap-y-4">
      <iframe
        className="w-[70%] h-[270px] lg:w-[430px]"
        src="https://www.youtube.com/embed/fbEqxrM-P6U"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>

    </div>
  );
};

export default Videolar;
